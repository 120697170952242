import { LitElement, html, css, customElement, property } from 'lit-element';
import mdcTypography from '@wces/mdc-typography';
import '@material/mwc-button';
import 'athena-fds/athena-fds.js';
import baseStyle from './base-style.js';
import { formatDate } from '../utils/format.js';
import './athena-pec-spinner.js';

@customElement('athena-pec-print')
export class AthenaPecPrint extends LitElement {
  @property({ type: Boolean }) active = false;

  /**
   * @type {{ api: {
   *    getFds: (numFds: String) => Promise<any>,
   *    getFdsFromPec: (pec: import('../types').IPecRequest) => Promise<any>,
   *    getPecRequest(requestId: string) => Promise<import('../types').IPecRequest>
   *   }
   * }}
   */
  @property({ type: Object }) context;

  /**
   * @type {{ params: { id: String }}}
   */
  @property({ type: Object }) routingParams;

  /**
   * @type { {navigate: (url: String) => Promise}}
   */
  @property({ type: Object }) router;

  @property({ type: Object }) _claim;

  @property({ type: Boolean }) showingSpinner = false;

  static styles = [
    baseStyle,
    mdcTypography,
    css`
      .container {
        position: relative;
      }
      .center {
        display: flex;
        justify-content: center;
        align-items: center;
      }

      @media print {
        .no-print {
          visibility: none;
          opacity: 0;
        }
        .print {
          position: absolute;
          left: 0;
          top: 0;
          visibility: visible;
          opacity: 1;
        }
      }
    `,
  ];

  quit = () => {
    this.router.navigate('/');
  };

  print = () => {
    window.print();
  };

  render() {
    return html`
      <div class="container">
        <div class="no-print center">
          <mwc-button class="no-print" @click="${this.quit}">Quitter</mwc-button>
          <mwc-button class="no-print" @click="${this.print}" outlined>Imprimer</mwc-button>
        </div>
        <p class="no-print caption center">
          Vous pouvez également faire clic-droit + imprimer pour imprimer.
        </p>
        ${this.showingSpinner
          ? html`
              <div class="center">
                <athena-pec-spinner></athena-pec-spinner>
              </div>
            `
          : ''}
        <div class="print center" id="print">
          ${this._claim
            ? html`
                <athena-fds id="fds" .claim="${this._claim}"></athena-fds>
              `
            : ''}
        </div>
      </div>
    `;
  }

  updated(changedProperties) {
    if (changedProperties.has('routingParams')) {
      this.showingSpinner = true;
      this._claim = null;
      const pecId = this.routingParams.params.id;
      const executable = this.stringToBool(this.routingParams.params.exec);

      let publicInsurancePaidAmount = 0;
      let payerPaidAmount = 0;

      this.context.api
        .getPecRequest(pecId)
        .then(async pec => {
          const fds = await this.getFds(pec, Boolean(executable));
          const claim = {
            date: formatDate(fds.datFds),
            claimNumber: fds.numFds,
            id: pec.id,
            pecNumber: pec.numfds,
            payer: fds.libPyr,
            publicInsurance: '',
            insurer: fds.libAss,
            policyholder: fds.libSou,
            policyNumber: fds.numPol,
            policyId: fds.codPol,
            patientPublicId: '',
            patientId: fds.codBen,
            patientName: fds.libBen,
            patientDob: formatDate(fds.naiBen),
            patientRelationship: fds.aceBpr,
            employeePublicId: '',
            employeeId: fds.lienBpr,
            employeeName: fds.libBpr,
            medicalProviderName: fds.libCen,
            medicalProviderId: fds.codCen,
            practionerName: fds.libPre,
            practionerId: fds.codPre,
            practionerMedicalSpecialty: fds.libMed,
            prescriptionDate: formatDate(fds.datCons),
            prescriptionPractionerName: fds.libPres,
            prescriptionPractionerId: fds.codPrsp,
            diagnosisCode: fds.codAff,
            typeOfService: fds.codTypp,
            hospitalisation: {
              startDate: formatDate(pec.validFrom),
              endDate: formatDate(pec.validTo),
              accomodationLimit: pec.roomMaxAmount,
              patientRate: pec.patientAmoutRate,
              payerRate: pec.supportedAmoutRate,
              globalLimit: pec.maxAmount,
            },
            patientPaidAmount: fds.mtrFds - fds.mtpFds,
            user: fds.nomUti,
            medicalServiceLines: fds.acts.map(aem => {
              const pub = aem.prestations.find(p => p.codBpo === 0) || { mtpPre: 0 };
              publicInsurancePaidAmount += pub.mtpPre;

              const pyr = aem.prestations.find(p => p.codBpo === 1) || { mtpPre: 0 };
              payerPaidAmount += pyr.mtpPre;
              return {
                medicalProcedureCode: aem.licTar,
                medicalServiceDescription: aem.libTar,
                serviceQuantity: aem.qteAemPrint,
                chargeAmount: aem.mtrAem,
                publicInsurancePaidAmount: pub.mtpPre,
                payerPaidAmount: pyr.mtpPre,
                patientPaidAmount: aem.mtrAem - aem.mtpAem,
              };
            }),
          };
          this._claim = {
            ...claim,
            publicInsurancePaidAmount,
            payerPaidAmount,
          };
          this.showingSpinner = false;
        })
        .catch(err => {
          console.error(err);
          this.showingSpinner = false;
        });
    }
  }

  /**
   *
   * @param {import('../types').IPecRequest} pec
   */
  getFds(pec, executable) {
    if (pec.status === 'EXECUTED') {
      return this.context.api.getFds(pec.execNumFds, pec.acts);
    }
    return this.context.api.getFdsFromPec(pec, executable);
  }

  $(selector) {
    return this.shadowRoot.querySelector(selector);
  }

  stringToBool = value => {
    let val = value;
    if (typeof val === 'string') {
      val = val.trim().toLowerCase();
    }
    switch (val) {
      case true:
      case 'true':
      case 1:
      case '1':
      case 'on':
      case 'yes':
        return true;
      default:
        return false;
    }
  };
}
