import { LitElement, html, customElement, property, css } from 'lit-element';
import { live } from 'lit-html/directives/live';
import '@material/mwc-checkbox';
import '@wces/datatable/wces-datatable.js';
import '@material/mwc-button';
import '@material/mwc-icon-button';
import '@material/mwc-snackbar';
import mdcTypography from '@wces/mdc-typography';
import baseStyle from './base-style.js';
import { formatNumber } from '../utils/format.js';
import './athena-pec-spinner.js';

@customElement('athena-pec-execute')
export class AthenaPecExecute extends LitElement {
  @property({ type: Boolean }) active = false;

  @property({ type: Boolean }) exec = true;

  /**
   * @type {{ api: { getPecRequest: (id: String) => any}}}
   */
  @property({ type: Object }) context;

  /**
   * @type {{ params: { id: String }}}
   */
  @property({ type: Object }) routingParams;

  /**
   * @type { {navigate: (url: String) => Promise}}
   */
  @property({ type: Object }) router;

  /**
   * @type {import('../types').IPecRequest}
   */
  @property({ type: Object }) _pec;

  @property({ type: Object }) _chosenActs = [];

  @property({ type: Boolean }) executing = false;

  @property({ type: Boolean }) submitDialogOpen = false;

  toggleAct = (id, checked, totalPrice) => {
    if (checked) {
      if (Number(this._pec.actType.id) === 8) {
        this._chosenActs = [...this._chosenActs, { id, insertedPrice: totalPrice }];
      } else {
        this._chosenActs = [...this._chosenActs, { id, insertedPrice: 0 }];
      }
    } else {
      this._chosenActs = this._chosenActs.filter(({ id: actId }) => actId !== id);
    }
    this.requestUpdate();
  };

  header = [];

  _priceChange = e => {
    const { id, value } = e.target;
    this._chosenActs = this._chosenActs.map(act => {
      if (act.id === id) {
        return {
          ...act,
          insertedPrice: Number(value),
        };
      }
      return act;
    });
    // console.log('Chosen acts', this._chosenActs);
  };

  quit = () => {
    this.router.navigate('/');
  };

  execute = () => {
    /**
     * @type {{api: import('../pec-api').PecAPI}}
     */
    const { api } = this.context;
    this.executing = true;
    api
      .executePecRequest({ id: this._pec.id, acts: this._chosenActs })
      .then(res => {
        this.executing = false;
        console.error('error while Executing====', res);
        this.router.navigate(`/print/${this._pec.id}/${this.exec}`);
      })
      .catch(err => {
        this.executing = false;
        console.error('error while Executing====', err);
        this.openSnackbar();
      });
  };

  static styles = [
    mdcTypography,
    baseStyle,
    css`
      main {
        display: grid;
        grid-template-columns: minmax(3.75rem, 1fr) [center-start] minmax(min-content, 70rem) [center-end] minmax(
            3.75rem,
            1fr
          );
      }
      .container {
        grid-column: center-start / center-end;
        display: grid;
        grid-template-columns: 1fr min-content min-content;
        grid-gap: 1rem;
      }
      .title {
        grid-column: 1 / -1;
        margin-bottom: 2rem;
      }
      .table {
        grid-column: 1 / -1;
        --mdc-theme-secondary: var(--mdc-theme-primary);
      }
      .cancel {
        grid-column: 2 / 3;
      }
      .submit {
        grid-column: 3 / 4;
      }
      .spinner-container {
        display: flex;
        justify-content: center;
      }
      .print-area {
        opacity: 0;
        position: absolute;
      }
    `,
  ];

  render() {
    if (!this._pec || !this.routingParams || this._pec.id !== this.routingParams.params.id) {
      return html`
        <div class="spinner-container">
          <athena-pec-spinner class="spinner"></athena-pec-spinner>
        </div>
      `;
    }
    const acts = this._pec.acts.map(act => ({
      ...act,
      chosen: !!this._chosenActs.find(({ id }) => act.id === id),
    }));
    return html`
      <main>
        <div class="container">
          <h1 class="title headline4">
            Exécution de la demande de prise en charge n° ${this._pec.id}
          </h1>
          <wces-datatable class="table" .header="${this.header}" .data="${acts}"> </wces-datatable>
          ${this.executing
            ? html`
                <athena-pec-spinner class="spinner"></athena-pec-spinner>
              `
            : html`
                <mwc-button @click="${this.quit}" class="cancel" label="Quitter"></mwc-button>
                <mwc-button
                  ?disabled="${!this._chosenActs.length}"
                  outlined
                  @click="${this.execute}"
                  class="submit"
                  label="Exécuter"
                >
                </mwc-button>
              `}
        </div>
        <mwc-snackbar id="snackbar" labelText="Une erreur est survenue. Réessayez ultérieurement">
          <mwc-icon-button icon="close" slot="dismiss"></mwc-icon-button>
        </mwc-snackbar>
      </main>
    `;
  }

  async updated(changedProperties) {
    if (changedProperties.has('routingParams')) {
      this._pec = null;
      const { id } = this.routingParams.params;
      this._pec = await this.context.api.getPecRequest(id);
      this._chosenActs = [];
      this.header = this.buildHeader();
    }
  }

  buildHeader() {
    const header = [
      {
        name: 'description',
        displayName: 'Acte',
      },
    ];
    const actionHeader = {
      name: '',
      displayName: '',
      renderer: ({ status, id, chosen, totalPrice }, tag) => {
        console.log('is checked', chosen, id, status, totalPrice);
        return tag`
          <mwc-checkbox type="checkbox"
            .checked="${live(chosen)}"
            ?disabled="${status !== 'VALIDATED'}"
            @change="${e => this.toggleAct(id, e.target.checked, totalPrice)}">
          <mwc-checkbox>
      `;
      },
    };
    if (Number(this._pec.actType.id) === 8) {
      // Hospitalisation
      let totalPrice = 0;
      let acceptedTotalPrice = 0;
      return [
        ...header,
        {
          name: 'totalPrice',
          displayName: 'Montant réclamé',
          renderer: (row, tag) => tag`<span>${formatNumber(row.unitPrice)}</span>`,
        },
        {
          name: 'acceptedTotalPrice',
          displayName: 'Montant pris en charge',
          renderer: (row, tag) => {
            totalPrice = 0;
            acceptedTotalPrice = 0;
            row.items.map(i => {
              totalPrice += i.totalPrice;
              acceptedTotalPrice += i.acceptedTotalPrice;
              return {
                totalPrice,
                acceptedTotalPrice,
              };
            });

            return tag`
            <span>${formatNumber(acceptedTotalPrice)}</span>
          `;
          },
        },
        {
          name: '',
          displayName: 'Part patient',
          renderer: (row, tag) =>
            tag`<span>${formatNumber(row.unitPrice - acceptedTotalPrice)}</span>`,
        },
        actionHeader,
      ];
    }
    return [
      ...header,
      {
        name: 'totalPrice',
        displayName: 'Montant réclamé',
        renderer: (row, tag) => tag`<span>${formatNumber(row.totalPrice)}</span>`,
      },
      {
        name: 'acceptedTotalPrice',
        displayName: 'Montant pris en charge',
        renderer: (row, tag) => tag`<span>${formatNumber(row.acceptedTotalPrice)}</span>`,
      },
      {
        name: '',
        displayName: 'Part patient',
        renderer: (row, tag) =>
          tag`<span>${formatNumber(row.totalPrice - row.acceptedTotalPrice)}</span>`,
      },
      actionHeader,
    ];
  }

  openSnackbar() {
    setTimeout(() => {
      this.$('#snackbar').open();
    }, 100);
  }

  $(selector) {
    return this.shadowRoot.querySelector(selector);
  }
}
