import { LitElement, html, customElement, css } from 'lit-element';
import baseStyle from './base-style.js';

@customElement('athena-pec-spinner')
export class AthenaPecSpinner extends LitElement {
  static styles = [
    baseStyle,
    css`
      .loader {
        display: inline-block;
        font-size: 1rem; /* Change this to resize the loader */
        animation: 2s rotate infinite linear;
        width: 1em;
        height: 1em;
      }

      .loader circle {
        fill: transparent;
        stroke: #2a8fbd;
        stroke-width: 0.1em;
        stroke-linecap: round;
        stroke-dasharray: 2.3525em 0.4705em;
        animation: 1.5s strokeDashArray infinite linear, 24s colorBounce infinite linear;
      }

      @keyframes rotate {
        from {
          transform: rotate(0deg);
        }

        to {
          transform: rotate(360deg);
        }
      }

      @keyframes strokeDashArray {
        from {
          stroke-dasharray: 2.3525em 0.4705em;
          stroke-dashoffset: 0;
        }

        10% {
          stroke-dasharray: 2.3525em 0.4705em;
          stroke-dashoffset: 0;
        }

        50% {
          stroke-dasharray: 0.001em 2.825em;
          stroke-dashoffset: -2.826em;
        }

        60% {
          stroke-dasharray: 0.001em 2.825em;
        }

        to {
          stroke-dasharray: 2.3525em 0.4705em;
          stroke-dashoffset: -2.826em;
        }
      }

      @keyframes colorBounce {
        1% {
          stroke: #2a8fbd;
        }

        24% {
          stroke: #2a8fbd;
        }

        26% {
          stroke: #8ebd2a;
        }

        49% {
          stroke: #8ebd2a;
        }

        51% {
          stroke: #bf2a2a;
        }

        74% {
          stroke: #bf2a2a;
        }

        76% {
          stroke: #ecb43e;
        }

        99% {
          stroke: #ecb43e;
        }
      }
    `,
  ];

  render() {
    return html`
      <div class="loader">
        <svg width="1em" height="1em"><circle cx="0.5em" cy="0.5em" r="0.45em" /></svg>
      </div>
    `;
  }
}
