import { createApolloClient } from './apollo.js';
import {
  GET_PEC_REQUESTS,
  ADD_PEC_REQUEST,
  GET_PEC_REQUEST,
  PEC_REQUEST_ADDED,
  PEC_REQUEST_UPDATED,
  PEC_REQUEST_EXECUTED,
  EXECUTE_PEC_REQUEST,
} from './requests.js';

export class PecAPI {
  addPecRequestSub = null;

  updatePecRequestSub = null;

  executePecRequestSub = null;

  constructor({ serverUrl, serverSubUrl, tokenProvider }) {
    this.client = createApolloClient({ serverUrl, serverSubUrl, tokenProvider });
  }

  getPecRequests = async () => {
    const { data, errors } = await this.client.query({ query: GET_PEC_REQUESTS });
    if (errors) {
      throw errors;
    }
    return data.pecRequests;
  };

  addPecRequest = async pecRequestInput => {
    // console.log('submitting', pecRequestInput);
    const { data, errors } = await this.client.mutate({
      mutation: ADD_PEC_REQUEST,
      variables: { pecRequestInput },
      update: (cache, { data: { addPECRequest } }) => {
        this.updateStore(addPECRequest);
      },
    });
    if (errors) {
      throw errors;
    }
    return data.addPECRequest;
  };

  getPecRequest = async requestId => {
    const { data, errors } = await this.client.query({
      query: GET_PEC_REQUEST,
      variables: { requestId },
    });
    if (errors) {
      throw errors;
    }
    return data.pecRequest;
  };

  executePecRequest = async pecRequestExecution => {
    const { data, errors } = await this.client.mutate({
      mutation: EXECUTE_PEC_REQUEST,
      variables: { pecRequestExecution },
      update: (cache, { data: { executePECRequest } }) => {
        this.updateStore(executePECRequest);
      },
    });
    if (errors) {
      throw errors;
    }
    return data.executePECRequest;
  };

  watchPecRequests = async (cb, subCb) => {
    await this.getPecRequests();
    if (this.addPecRequestSub) {
      this.addPecRequestSub.unsubscribe();
    }
    this.addPecRequestSub = this.subscribeToPecRequests(subCb);
    if (this.updatePecRequestSub) {
      this.updatePecRequestSub.unsubscribe();
    }
    this.updatePecRequestSub = this.subscribeToPecRequestUpdate(subCb);
    if (this.executePecRequestSub) {
      this.executePecRequestSub.unsubscribe();
    }
    this.executePecRequestSub = this.subscribeToPecRequestExecute(subCb);
    this.client.watchQuery({ query: GET_PEC_REQUESTS }).subscribe(({ data, error }) => {
      if (error) {
        cb({ error });
      } else {
        cb({ pecRequests: data.pecRequests });
      }
    });
  };

  subscribeToPecRequests = cb =>
    this.client.subscribe({ query: PEC_REQUEST_ADDED }).subscribe(({ data, error }) => {
      if (cb) {
        cb({ data, error });
      }
      if (!error) {
        this.updateStore(data.pecRequestAdded);
      } else {
        console.error(error);
      }
    });

  subscribeToPecRequestUpdate = cb =>
    this.client.subscribe({ query: PEC_REQUEST_UPDATED }).subscribe(({ data, error }) => {
      if (cb) {
        cb({ data, error });
      }
      if (!error) {
        this.updateStore(data.pecRequestUpdated);
      } else {
        console.error(error);
      }
    });

  subscribeToPecRequestExecute = cb =>
    this.client.subscribe({ query: PEC_REQUEST_EXECUTED }).subscribe(({ data, error }) => {
      if (cb) {
        cb({ data, error });
      }
      if (error) {
        console.error(error);
      } else {
        this.updateStore(data.pecRequestExecuted);
      }
    });

  updateStore = incomingPecRequest => {
    let { pecRequests } = this.client.readQuery({ query: GET_PEC_REQUESTS });

    pecRequests = pecRequests.filter(({ id }) => id !== incomingPecRequest.id);
    this.client.writeQuery({
      query: GET_PEC_REQUESTS,
      data: { pecRequests: [...pecRequests, incomingPecRequest] },
    });
  };

  clear = () => {
    if (this.addPecRequestSub) {
      this.addPecRequestSub.unsubscribe();
    }
    if (this.updatePecRequestSub) {
      this.updatePecRequestSub.unsubscribe();
    }
    if (this.executePecRequestSub) {
      this.executePecRequestSub.unsubscribe();
    }
    this.client.clearStore();
  };
}
