import { LitElement, html, customElement, css, property } from 'lit-element';
import mdcTypography from '@wces/mdc-typography';
import baseStyle from './base-style.js';
import './athena-pec-spinner.js';
import { formatDate } from '../utils/format.js';

const formatStatus = status => {
  switch (status) {
    case 'A':
      return 'Assuré';
    case 'C':
      return 'Conjoint(e)';
    case 'E':
      return 'Enfant';
    default:
      return '';
  }
};

@customElement('athena-pec-patient-info')
export class PatientInfo extends LitElement {
  @property({ type: Boolean }) loading = false;

  @property({ type: Object }) patient;

  static styles = [
    baseStyle,
    mdcTypography,
    css`
      .container {
        display: grid;
        grid-template-columns: max-content max-content;
        grid-gap: 0.5rem;
      }
      .title {
        grid-column: 1 / -1;
      }
      .spinner-container {
        display: flex;
        align-items: center;
        justify-content: flex-start;
      }
    `,
  ];

  render() {
    if (this.loading) {
      return html`
        <athena-pec-spinner></athena-pec-spinner>
      `;
    }
    if (this.patient) {
      if (this.patient.error) {
        return html`
          <div class="container">
            <h4 class="title headline6">${this.patient.error}</h4>
          </div>
        `;
      }
      if (this.patient.from) {
        return html`
          <div class="container">
            <h4 class="title headline6">${this.patient.name} - ${this.patient.id}</h4>
            <span class="birthday caption">Né le ${formatDate(this.patient.birthday)}</span>
            <span class="status caption">${formatStatus(this.patient.status)}</span>
          </div>
        `;
      }
      return html`
        <div class="container">
          <h4 class="title headline6">${this.patient.name}</h4>
          <span class="birthday caption">Né le ${formatDate(this.patient.birthday)}</span>
          <span class="status caption">${formatStatus(this.patient.status)}</span>
        </div>
      `;
    }
    return html``;
  }
}
