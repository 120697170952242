import { LitElement, customElement, html, property, css } from 'lit-element';
import { live } from 'lit-html/directives/live';
import '@material/mwc-button';
import '@material/mwc-textfield';
import '@material/mwc-textarea';
import '@material/mwc-select';
import '@material/mwc-icon-button';
import '@material/mwc-list/mwc-list-item';
import '@material/mwc-dialog';
import mdcTypography from '@wces/mdc-typography';
import baseStyle from './base-style.js';
import './athena-pec-patient-info.js';
import { debounce } from '../utils/debounce.js';
import './athena-pec-file-input.js';
import { sortBy } from '../utils/compare.js';

@customElement('create-form-one')
export class CreateFormOne extends LitElement {
  @property({ type: Boolean }) active;

  @property({ type: Object }) api;

  @property({ type: Boolean }) valid;

  @property({ type: Object }) _state = {
    loadingPatient: false,
    diseaseCodes: [],
    actTypes: [],
    practitioners: [],
    patientId: '',
    patient: null,
    prescriber: null,
    pecOrBen: {
      from: 'BEN',
    },
    practitionerId: '',
    providerPresc: null,
    actTypeId: '',
    diseaseCode: '',
    admissionDate: '',
    estimatedDuration: '',
    observations: '',
    attachments: null,
    confirmDialogOpen: true,
    numPresc: '',
    actHospitTypeId: null,
    actHospitTypes: [],
    codcre: null,
  };

  _generalFieldsSelectors = [
    '#patient',
    '#actType',
    '#practitioner',
    '#diseaseCode',
    '#observations',
  ];

  _hospitFieldsSelectors = ['#admissionDate', '#estimatedDuration', '#actHospitType'];

  static styles = [
    baseStyle,
    mdcTypography,
    css`
      .form {
        display: grid;
        grid-template-columns:
          minmax(0rem, 1fr) [center-start] repeat(
            2,
            [col-start] minmax(min-content, 20rem) [col-end]
          )
          [center-end] minmax(0rem, 1fr);
        grid-template-rows: repeat(5, min-content);
        grid-gap: 1rem;
        align-items: start;
        border: solid 1px rgba(0, 0, 0, 0.01);
        padding: 3rem 0;
        box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.08);
      }
      .formfield--left {
        grid-column: col-start 1 / col-end 1;
      }
      .formfield--full {
        grid-column: center-start / center-end;
      }
      .remarks-field {
        min-height: 10rem;
      }
      .dropdown-item {
        padding: 0 1rem;
      }
    `,
  ];

  _actTypeIdChange = async ({ target: { value } }) => {
    this.setState({ actTypeId: value });
    if (this._state.practitionerId) {
      this.setState({ practitionerId: '' });
    }
    try {
      const practitioners = sortBy(await this.api.getPractitioners({ actTypeId: value }), 'name');
      if (Number(this._state.actTypeId) === 8) {
        const actHospitTypes = sortBy(await this.api.getActHospitTypes({}), 'name');
        this.setState({ practitioners, actHospitTypes });
      } else {
        this.setState({ practitioners });
      }
    } catch (err) {
      console.error(err);
    } finally {
      this.reportValidity();
    }
  };

  _patientIdChange = ({ target: { value } }) => {
    this.setState({ patientId: value });
    this._lookupPatient(value);
  };

  _lookupPatient = debounce(async patientId => {
    this.setState({ loadingPatient: true, patient: null });
    try {
      const user = await this.api.getUser({});
      this.setState({
        pecOrBen: await this.api.getPatient({ patientId }),
        codcre: String(user.codCre),
      });
      this.reportValidity();
      if (this._state.pecOrBen) {
        this.setState({ patient: this._state.pecOrBen.patient });
        if (this._state.pecOrBen.from === 'PRESC' && !this._state.pecOrBen.error) {
          this.setState({
            actTypeId: this._state.pecOrBen.pec.actType.id,
            diseaseCode: this._state.pecOrBen.pec.diseaseCode,
            prescriber: this._state.pecOrBen.pec.prescriber,
            providerPresc: this._state.pecOrBen.pec.providerPresc,
          });
          this.reportValidity();
        }
      }
    } catch (err) {
      console.error(err);
    } finally {
      this.setState({ loadingPatient: false });
      this.reportValidity();
    }
  });

  _validatePatient = (newValue, nativeValidity) => {
    const { loadingPatient, patient } = this._state;
    if (nativeValidity.valid) {
      if (loadingPatient) {
        return { valid: true };
      }
      if (patient) {
        if (patient.error) {
          return { valid: false };
        }
        return { valid: true };
      }
    }
    return { valid: false };
  };

  _practitionerIdChange = async ({ target: { value } }) => {
    this.setState({ practitionerId: value });
    if (this._state.pecOrBen.from === 'BEN') {
      const providerPresc = await this.api.getProvider();
      this.setState({
        prescriber: { id: value, name: '' },
        providerPresc: { id: providerPresc.id, name: providerPresc.name },
      });
    }
    this.reportValidity();
  };

  _admissionDateChange = async ({ target: { value } }) => {
    this.setState({ admissionDate: value });
    this.reportValidity();
  };

  _estimatedDurationChange = async ({ target: { value } }) => {
    this.setState({ estimatedDuration: value });
    this.reportValidity();
  };

  _diseaseCodeChange = async ({ target: { value } }) => {
    this.setState({ diseaseCode: value });
    this.reportValidity();
  };

  _validateDiseaseCode = (newValue, nativeValidity) => {
    if (!newValue) {
      return nativeValidity;
    }
    return {
      valid: nativeValidity.valid && this._state.diseaseCodes.includes(newValue),
    };
  };

  _actHospitTypeIdChange = async ({ target: { value } }) => {
    const actHospitType = await this.api.getActHospitType({ actHospitTypeId: value });
    this.setState({
      actHospitTypeId: value,
      actHospitType: { id: actHospitType.id, name: actHospitType.name },
    });

    this.reportValidity();
  };

  _observationsChange = ({ target: { value } }) => this.setState({ observations: value });

  _attachmentChange = ({ detail: { files } }) => {
    this.setState({ attachments: files });
  };

  render() {
    const {
      loadingPatient,
      patientId,
      patient,
      actTypes,
      actTypeId,
      diseaseCode,
      practitioners,
      practitionerId,
      observations,
    } = this._state;

    return html`
      <form class="form">
        <mwc-textfield
          id="patient"
          .validityTransform="${this._validatePatient}"
          .value="${live(patientId)}"
          @input="${this._patientIdChange}"
          class="formfield formfield--left"
          required
          outlined
          label="Matricule ou N° Prescription"
        >
        </mwc-textfield>

        <athena-pec-patient-info
          ?loading="${loadingPatient}"
          .patient="${patient}"
          class="patient-box"
        >
        </athena-pec-patient-info>

        <mwc-select
          id="actType"
          .value="${actTypeId}"
          @change="${this._actTypeIdChange}"
          class="formfield formfield--full"
          required
          outlined
          label="Type de prestation"
        >
          ${actTypes.map(
            ({ id, name }) => html`
              <mwc-list-item class="dropdown-item" value="${id}">
                <span class="body1">${name}</span>
              </mwc-list-item>
            `,
          )}
        </mwc-select>
        ${this._renderTypeHospit()}
        <mwc-select
          id="practitioner"
          .value="${live(practitionerId)}"
          @change="${this._practitionerIdChange}"
          required
          class="formfield formfield--full"
          outlined
          label="Prestataire médical"
        >
          ${practitioners.map(
            ({ id, name }) => html`
              <mwc-list-item class="dropdown-item" value="${id}">
                <span class="body1">${name}</span>
              </mwc-list-item>
            `,
          )}
        </mwc-select>

        <mwc-textfield
          id="diseaseCode"
          .validityTransform="${this._validateDiseaseCode}"
          .value="${live(diseaseCode)}"
          @input="${this._diseaseCodeChange}"
          type="number"
          class="formfield formfield--full"
          outlined
          required
          label="Code affection"
        >
        </mwc-textfield>

        ${this._renderHospit()}

        <athena-pec-file-input
          class="formfield formfield--full"
          @change="${this._attachmentChange}"
        >
        </athena-pec-file-input>

        <mwc-textarea
          id="observations"
          .value="${live(observations)}"
          @input="${this._observationsChange}"
          class="formfield formfield--full"
          rows="4"
          outlined
          label="Remarques"
        >
        </mwc-textarea>
      </form>
    `;
  }

  _renderTypeHospit() {
    if (Number(this._state.actTypeId) === 8 && this._state.actHospitTypes !== null) {
      const { actHospitTypes, actHospitTypeId } = this._state;
      return html`
        <mwc-select
          id="actHospitType"
          .value="${live(actHospitTypeId)}"
          @change="${this._actHospitTypeIdChange}"
          class="formfield formfield--full"
          required
          outlined
          label="Type d'hospitalisation"
        >
          ${actHospitTypes.map(
            ({ id, name }) => html`
              <mwc-list-item class="dropdown-item" value="${id}">
                <span class="body1">${name}</span>
              </mwc-list-item>
            `,
          )}
        </mwc-select>
      `;
    }
    return html``;
  }

  _renderHospit() {
    if (Number(this._state.actTypeId) === 8) {
      // Hospitalisation
      const { admissionDate, estimatedDuration } = this._state;
      return html`
        <mwc-textfield
          id="admissionDate"
          .value="${live(admissionDate)}"
          @input="${this._admissionDateChange}"
          type="date"
          class="formfield formfield--left"
          required
          outlined
          label="Date d'entrée"
        >
        </mwc-textfield>

        <mwc-textfield
          id="estimatedDuration"
          .value="${live(estimatedDuration)}"
          @input="${this._estimatedDurationChange}"
          helper="nombre de jours"
          type="number"
          class="formfield"
          min="1"
          max="15"
          required
          outlined
          label="Durée prévisionnelle"
        >
        </mwc-textfield>
      `;
    }
    return html``;
  }

  async updated(changedProperties) {
    if (changedProperties.has('api') && this.api) {
      try {
        this.setState({
          actTypes: sortBy(await this.api.getActTypes(), 'name'),
          diseaseCodes: await this.api.getDiseaseCodes(),
        });
      } catch (err) {
        console.error(err);
      }
    }
    if (changedProperties.has('active') && this.active) {
      this.reportValidity();
    }

    if (changedProperties.has('_loadingPatient') && !this._loadingPatient) {
      try {
        this.$('#patient').reportValidity();
      } catch (err) {
        console.log(err);
      }
    }
  }

  get _hospitValid() {
    if (Number(this._state.actTypeId) !== 8) {
      // Hospitalisation
      return true;
    }
    return this._hospitFieldsSelectors.every(selector => this.$(selector).validity.valid);
  }

  reportValidity() {
    setTimeout(() => {
      this.dispatchEvent(new CustomEvent('validityChange', { detail: { valid: this.valid } }));
    }, 100);
  }

  get valid() {
    return (
      this._generalFieldsSelectors.every(selector => this.$(selector).validity.valid) &&
      this._hospitValid &&
      this._validateDiseaseCode
    );
  }

  get hospitValue() {
    if (this._actType === 8) {
      return {
        admissionDate: this.$('#admissionDate').value,
        estimatedDuration: this.$('#estimatedDuration').value,
        actHospitTypeId: this.$('#actHospitType').value,
      };
    }
    return {};
  }

  get value() {
    const {
      patient,
      actTypes,
      actTypeId,
      pecOrBen,
      diseaseCode,
      prescriber,
      providerPresc,
      practitioners,
      practitionerId,
      observations,
      admissionDate,
      estimatedDuration,
      attachments,
      actHospitTypeId,
      actHospitType,
      codcre,
    } = this._state;
    return {
      actType: actTypes.find(({ id }) => id === Number(actTypeId)),
      patient,
      actsPreloaded: pecOrBen.pec ? pecOrBen.pec.acts : [],
      from: pecOrBen.from,
      error: pecOrBen.error,
      practitioner: practitioners.find(({ id }) => id === practitionerId),
      prescriber,
      providerPresc,
      diseaseCode: Number(diseaseCode),
      observations,
      admissionDate,
      estimatedDuration: Number(estimatedDuration),
      attachments,
      numPresc: pecOrBen.pec ? pecOrBen.pec.numPresc : '',
      numPrescFds: pecOrBen.pec ? pecOrBen.pec.numPrescFds : '',
      codcre,
      actHospitTypeId: Number(actHospitTypeId),
      actHospitType,
    };
  }

  setState(obj) {
    this._state = {
      ...this._state,
      ...obj,
    };
  }

  $(selector) {
    return this.shadowRoot.querySelector(selector);
  }
}
