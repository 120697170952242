import { LitElement, html, css, customElement, property } from 'lit-element';
import '@wces/router/wces-router-brain.js';

import typography from '@wces/mdc-typography';

import baseStyle from './components/base-style.js';
import './components/athena-pec-list.js';
import './components/athena-pec-create.js';
import './components/athena-pec-view.js';
import './components/athena-pec-execute.js';
import './components/athena-pec-print.js';
import { PecAPI } from './pec-api.js';
import scrolltop from './utils/scrolltop.js';

@customElement('athena-pec')
export class AthenaPec extends LitElement {
  @property({ type: String }) serverurl;

  @property({ type: String }) serversuburl;

  @property({ type: Object }) terminalapi = {};

  @property({ type: Object }) _state = {
    location: { pathname: '/', search: '' },
    router: {
      navigate: async url => {
        this.setState({
          location: { pathname: url, search: '' },
        });
        scrolltop();
      },
    },
    routes: [],
  };

  /**
   * @type {PecAPI}
   */
  pecAPI = null;

  static styles = [
    baseStyle,
    typography,
    css`
      main {
        min-height: 80vh;
        display: grid;
        grid-template-columns: minmax(3.75rem, 1fr) [center-start] minmax(min-content, 70rem) [center-end] minmax(
            3.75rem,
            1fr
          );
      }
      .content {
        grid-column: center-start / center-end;
      }
    `,
  ];

  render() {
    const { router, routes, location } = this._state;
    return html`
      <wces-router-brain .router="${router}" .routes="${routes}" .location="${location}">
      </wces-router-brain>
    `;
  }

  firstUpdated(changedProperties) {
    if (super.firstUpdated) {
      super.firstUpdated(changedProperties);
    }
    this.pecAPI = new PecAPI({
      serverUrl: this.serverurl,
      tokenProvider: this.terminalapi,
      serverSubUrl: this.serversuburl,
    });
    const api = {
      ...this.terminalapi,
      ...this.pecAPI,
    };
    const routes = [
      {
        id: 'list',
        pattern: '(/)',
        component: 'athena-pec-list',
        context: async () => ({ api }),
      },
      {
        id: 'create',
        pattern: '/create',
        component: 'athena-pec-create',
        context: async () => ({ api }),
      },
      {
        id: 'view',
        pattern: '/view/:id',
        component: 'athena-pec-view',
        context: async () => ({ api }),
      },
      {
        id: 'execute',
        pattern: '/execute/:id',
        component: 'athena-pec-execute',
        context: async () => ({ api }),
      },
      {
        id: 'print',
        pattern: '/print/:id/:exec',
        component: 'athena-pec-print',
        context: async () => ({ api }),
      },
    ];
    this.setState({ routes });
  }

  disconnectedCallback() {
    console.log('Will disconnect athena-pec');
    if (this.pecAPI) {
      this.pecAPI.clear();
    }
    if (super.disconnectedCallback) {
      super.disconnectedCallback();
    }
  }

  setState(obj) {
    this._state = {
      ...this._state,
      ...obj,
    };
  }
}
