/**
 *
 * @param {Number[]} arr
 * @param {String} delimiter
 */
export const numArraytoString = (arr, delimiter) => {
  let str = '';
  let i = 0;
  arr.forEach(e => {
    if (i <= 0) {
      str = str.concat('', e);
    } else {
      str = str.concat(delimiter, e);
    }
    i += 1;
  });
  return str;
};
