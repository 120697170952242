import { LitElement, customElement, html, css, property } from 'lit-element';
import { live } from 'lit-html/directives/live';
import '@material/mwc-button';
import '@material/mwc-icon-button';
import '@material/mwc-textfield';
import '@wces/datatable/wces-datatable.js';
import mdcTypography from '@wces/mdc-typography';
import baseStyle from './base-style.js';
import './athena-pec-autocomplete.js';
import './athena-pec-add-teeth.js';
import { sortBy } from '../utils/compare.js';
import { formatNumber } from '../utils/format.js';

@customElement('create-form-two')
export class CreateFormtWO extends LitElement {
  @property({ type: Boolean }) active;

  @property({ type: Object }) patient;

  @property({ type: Number }) actTypeId;

  @property({ type: String }) practitionerId;

  @property({ type: Object }) api;

  @property({ type: Array }) actsPreloaded;

  @property({ type: Boolean }) actsPreloadedDone;

  @property({ type: String }) from;

  @property({ type: Object }) _state = {
    header: null,
    data: [],
    acts: [],
    teethList: [],
    teethNum: [],
    toothNumber: '',
  };

  headerBase = [{ name: 'description', displayName: 'Acte' }];

  _priceChange = e => {
    const { id, value } = e.target;
    this.setState({
      data: this._state.data.map(item => {
        if (item.id !== id) {
          return item;
        }
        return {
          ...item,
          unitPrice: Number(value),
        };
      }),
    });
    this.reportValidity();
  };

  priceHeader = {
    name: '',
    displayName: 'Total',
    renderer: (row, tag) => {
      const price = Number(row.unitPrice) * Number(row.quantity) * Number(row.quantityPrint);
      if (row.freePrice) {
        let isRequired = true;
        let min = 1;
        if (this.actTypeId === 8) {
          // Hospitalisation
          isRequired = false;
          min = 0;
        }
        return tag`
          <mwc-textfield
            .value="${price}" id="${row.id}"
            @change="${this._priceChange}" ?required="${isRequired}" type="number" min="${min}">
          </mwc-textfield>
        `;
      }
      return tag`<span>${formatNumber(price)}</span>`;
    },
  };

  _removeAct = e => {
    const { id } = e.target;
    this.setState({
      data: this._state.data.filter(item => item.id !== id),
    });
    this.reportValidity();
  };

  actionHeader = {
    name: '',
    renderer: (row, tag) => tag`
      <mwc-icon-button
        @click="${this._removeAct}" id="${row.id}" icon="delete_outline">
      </mwc-icon-button>
    `,
  };

  _quantityChange = e => {
    const { id, value } = e.target;
    this.setState({
      data: this._state.data.map(item => {
        if (item.id !== id) {
          return item;
        }
        return {
          ...item,
          quantity: item.lettreClee.is ? item.lettreClee.val : 1,
          quantityPrint: item.lettreClee.is && this.actsPreloaded.length > 0 ? 1 : Number(value),
        };
      }),
    });
    this.reportValidity();
  };

  quantityHeader = {
    name: '',
    displayName: 'Quantité',
    renderer: (row, tag) => tag`
      <mwc-textfield
        .value="${row.quantityPrint}" id="${row.id}"
        @change="${this._quantityChange}"
        required type="number" min="1">
      </mwc-textfield>
    `,
  };

  static styles = [
    baseStyle,
    mdcTypography,
    css`
      .container {
        display: grid;
        grid-template-columns: minmax(0, 1fr) [center-start] minmax(min-content, 60rem) [center-end] minmax(
            0,
            1fr
          );
        grid-template-rows: max-content max-content;
        grid-gap: 2rem;
        border: solid 1px rgba(0, 0, 0, 0.01);
        padding: 3rem 0;
        box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.08);
      }
      .title {
        grid-column: center-start / center-end;
      }
      .form {
        grid-column: center-start / center-end;
        display: grid;
        grid-template-columns: 1fr max-content;
        align-items: center;
        grid-column-gap: 0.5rem;
        grid-row-gap: 2rem;
      }

      .table {
        grid-column: 1 / -1;
      }
      #search {
        grid-column: 1 / -1;
      }
    `,
  ];

  _searchAct = async ({ detail: { value } }) => {
    try {
      const acts = await this.api.getActs(this.actTypeId, this.practitionerId, value.trim());
      if (this.actTypeId === 6) {
        const teeth = await this.api.getToothNumber({});
        this.setState({
          acts: sortBy(
            acts.filter(({ id }) => !this._state.data.find(d => d.id === id)),
            'name',
          ).slice(0, 10),
        });
        this._state.teethList.push({ id: 0, value: sortBy(teeth, 'name') });
      } else {
        this.setState({
          acts: sortBy(
            acts.filter(({ id }) => !this._state.data.find(d => d.id === id)),
            'name',
          ).slice(0, 10),
        });
      }
    } catch (err) {
      console.error('No Act!');
    }
  };

  _addAct = async () => {
    this.actsPreloaded = [];
    const {
      value: { id, name: description },
      isValid,
    } = this.$('#search');
    const { price, freePrice, quantity, lettreClee } = await this.api.getActPrice(
      id,
      this.practitionerId,
      this.patient.id,
      1,
    );
    if (isValid) {
      this.setState({
        data: [
          ...this._state.data,
          {
            id,
            description,
            unitPrice: lettreClee.is ? lettreClee.vltTle : price,
            freePrice,
            quantity: lettreClee.is ? lettreClee.val : quantity,
            quantityPrint: 1,
            lettreClee,
            toothNumber: null,
          },
        ],
      });
      if (this.actTypeId === 6) {
        const index = this._state.teethList.findIndex(i => i.id === 0);
        this._state.teethList[index].id = id;
      }

      this.$('#search').clear();
      this.reportValidity();
    }
  };

  _addTooth = e => {
    const { id } = e.target;
    const actId = id.split('-')[1];
    const toothNum = e.detail.value._value;

    const check = this._state.teethNum.find(i => i.id === actId && i.number === toothNum);
    const inside = check ? check.number === toothNum : false;
    if (!inside) {
      this.setState({
        data: this._state.data.map(item => {
          let qte = item.quantityPrint;
          let newtoothArray = [];
          if (item.id !== actId) {
            return item;
          }
          this._state.teethNum.push({ id: actId, number: toothNum });
          if (item.toothNumber != null) {
            newtoothArray = item.toothNumber;
            newtoothArray.push(toothNum);
            qte += 1;
          } else {
            newtoothArray = [];
            newtoothArray.push(toothNum);
            qte = 1;
          }
          return {
            ...item,
            toothNumber: newtoothArray,
            quantityPrint: qte,
          };
        }),
        teethList: this._state.teethList.map(item => {
          if (item.id !== actId) {
            return item;
          }
          const toothIndex = item.value.findIndex(i => i._id === toothNum);
          const tmpItmVal = item.value;
          tmpItmVal[toothIndex].selected = true;
          return {
            ...item,
            value: tmpItmVal,
          };
        }),
      });
      this.reportValidity();
      this._state.toothNumber = '';
    }
  };

  _removeTooth = e => {
    const { id } = e.target;
    const actId = id.split('-')[1];
    const toothNum = e.detail.value._value;

    this.setState({
      data: this._state.data.map(item => {
        let newtoothArray = [];
        let qte = item.quantityPrint;
        if (item.id !== actId) {
          return item;
        }
        if (item.toothNumber != null) {
          this.setState({
            teethNum: this._state.teethNum.filter(i => {
              if (i.id === actId) {
                return i.number !== Number(toothNum);
              }
              return i.id !== actId && i.number !== Number(toothNum);
            }),
            teethList: this._state.teethList.map(it => {
              if (it.id !== actId) {
                return it;
              }
              const toothIndex = it.value.findIndex(i => i._id === toothNum);
              const tmpItmVal = it.value;
              tmpItmVal[toothIndex].selected = false;
              return {
                ...it,
                value: tmpItmVal,
              };
            }),
          });
          newtoothArray = item.toothNumber.filter(i => i !== Number(toothNum));
          qte -= 1;
        }
        return {
          ...item,
          toothNumber: newtoothArray,
          quantityPrint: qte,
        };
      }),
    });
    this.reportValidity();
  };

  _toothNumChange = async ({ target: { value } }) => {
    this.setState({ toothNumber: Number(value) });
  };

  _validateToothNum = (newValue, nativeValidity) => {
    if (!newValue) {
      return nativeValidity;
    }
    return {
      valid:
        nativeValidity.valid &&
        this._state.teethList.find(e => e.name.search(String(newValue)) !== -1) !== undefined,
    };
  };

  toothHeader = {
    name: 'toothNumber',
    displayName: 'Numéro(s) de dent(s)',
    renderer: (row, tag) => {
      if (row.toothNumber) {
        const { toothNumber } = row;
        return tag`
              ${toothNumber.map(
                item => html`
                  <mwc-button id="${`${item}-${row.id}`}" label="${item}"></mwc-button>
                `,
              )}
      `;
      }
      return tag``;
    },
  };

  addToothHeader = {
    name: 'addToothNumber',
    displayName: ' Ajouter numéro(s) de dent(s)',
    renderer: (row, tag) => tag`
      <athena-pec-add-teeth
        id="${`toothSelector-${row.id}`}"
        .data="${live(
          this._state.teethList.length <= 0
            ? []
            : this._state.teethList.find(i => i.id === row.id).value,
        )}"
        @remove="${this._removeTooth}"
        @select="${this._addTooth}"
        class="toothSelector"
      ></athena-pec-add-teeth>
  `,
  };

  loadRemoteAct = async a => {
    const { price, freePrice, lettreClee } = await this.api.getActPrice(
      a.id,
      this.practitionerId,
      this.patient.id,
      a.quantity,
    );
    this.setState({
      data: [
        ...this._state.data,
        {
          id: a.id,
          description: a.description,
          unitPrice: lettreClee.is ? lettreClee.vltTle : price,
          freePrice,
          quantity: lettreClee.is ? lettreClee.val : 1,
          quantityPrint: a.quantity,
          lettreClee,
          toothNumber: null,
        },
      ],
    });
    this.reportValidity();
  };

  render() {
    if (this.from === 'PRESC' && this.actsPreloaded && !this.actsPreloadedDone) {
      this.actsPreloaded.forEach(a => {
        this.loadRemoteAct(a);
      });
      this.actsPreloadedDone = true;
    }
    const { header, data } = this._state;
    return html`
      <div class="container">
        <h4 class="headline6 title">Patient: ${this.patient ? this.patient.name : ''}</h4>
        <form class="form">
          ${this.from === 'BEN' || (this.from === 'PRESC' && this.actsPreloaded.length === 0)
            ? html`
                <athena-pec-autocomplete
                  id="search"
                  .data="${this._state.acts}"
                  @search="${this._searchAct}"
                  @select="${this._addAct}"
                  class="search"
                ></athena-pec-autocomplete>
              `
            : html``}
          ${header && data && data.length
            ? html`
                <wces-datatable class="table" .header="${header}" .data="${data}"> </wces-datatable>
              `
            : html``}
        </form>
      </div>
    `;
  }

  updated(changedProperties) {
    if (changedProperties.has('active') && this.active) {
      this.reportValidity();
    }
    if (changedProperties.has('actTypeId')) {
      if (this.actTypeId === 6) {
        // Dentisterie
        this.setState({
          header: [
            ...this.headerBase,
            this.toothHeader,
            this.addToothHeader,
            this.priceHeader,
            this.actionHeader,
          ],
        });
      } else if (
        this.actTypeId === 10 ||
        this.actTypeId === 4 ||
        this.actTypeId === 7 ||
        this.actTypeId === 12
      ) {
        // Auxiliaires médicaux, Autres Spécialités, Soins ou Pharmacie => saisie quantité
        this.setState({
          header: [...this.headerBase, this.quantityHeader, this.priceHeader, this.actionHeader],
        });
      } else {
        this.setState({
          header: [...this.headerBase, this.priceHeader, this.actionHeader],
        });
      }
      this.resetActs();
    }
    if (changedProperties.has('practitionerId')) {
      this.resetActs();
    }
  }

  resetActs() {
    this.$('#search').clear();
    this.setState({
      data: [],
    });
  }

  get value() {
    return {
      acts: this._state.data,
      toothNum: this._state.toothNum,
    };
  }

  get valid() {
    const { data } = this._state;
    return (
      data &&
      data.length &&
      data.every(({ quantity, unitPrice, toothNumber }) => {
        if (Number(quantity) >= 1) {
          if (this.actTypeId === 6) {
            // Dentisterie
            if (toothNumber) {
              return Number(toothNumber.length) > 0;
            }
            return false;
          }
          if (this.actTypeId === 8) {
            // Hospitalisation
            return Number(unitPrice) >= 0;
          }
          return Number(unitPrice) > 0;
        }
        return false;
      })
    );
  }

  $(selector) {
    return this.shadowRoot.querySelector(selector);
  }

  reportValidity() {
    this.dispatchEvent(new CustomEvent('validityChange', { detail: { valid: this.valid } }));
  }

  setState(obj) {
    this._state = {
      ...this._state,
      ...obj,
    };
  }
}
