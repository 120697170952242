import { LitElement, customElement, html, property, css } from 'lit-element';
import '@material/mwc-button';
import '@material/mwc-icon-button';
import '@material/mwc-snackbar';
import '@material/mwc-fab';
import '@material/mwc-textfield';
import '@material/mwc-textarea';
import '@material/mwc-select';
import '@material/mwc-dialog';
import '@material/mwc-list/mwc-list-item';
import mdcTypography from '@wces/mdc-typography';
import baseStyle from './base-style.js';
import './create-form-one.js';
import './create-form-two.js';
import './create-form-three.js';
import './athena-pec-progress.js';
import './athena-pec-spinner.js';
import scrolltop from '../utils/scrolltop.js';
import { numArraytoString } from '../utils/convertTo.js';

@customElement('athena-pec-create')
export class AthenaPecCreate extends LitElement {
  @property({ type: Boolean }) active = false;

  @property({ type: Object }) context;

  /**
   * @type {import('../types').Router}
   */
  @property({ type: Object }) router;

  @property({ type: Object }) _formAPI;

  @property({ type: Boolean }) _formValid = false;

  @property({ type: Number }) _actType;

  @property({ type: Number }) _step = 1;

  _progressSteps = ['Informations générales', 'Actes médicaux', 'Soumission'];

  @property({ type: Object }) _state = {
    formAPI: null,
    idPec: '',
    submitting: false,
    submitDialogOpen: false,
    formValid: false,
    step: 1,
    actType: null,
    patient: null,
    actsPreloaded: null,
    from: '',
    error: null,
    practitioner: null,
    prescriber: null,
    providerPresc: null,
    actHospitType: null,
    diseaseCode: '',
    observations: '',
    admissionDate: '',
    estimatedDuration: '',
    acts: [],
    attachments: [],
    numPresc: '',
    numPrescFds: '',
    codcre: '',
    actHospitTypeId: null,
  };

  static styles = [
    baseStyle,
    mdcTypography,
    css`
      main {
        display: grid;
        grid-template-columns: minmax(3.75rem, 1fr) [center-start] minmax(min-content, 70rem) [center-end] minmax(
            3.75rem,
            1fr
          );
      }
      .container {
        grid-column: center-start / center-end;
        display: grid;
        grid-template-columns: max-content 1fr [secondary-start] max-content [secondary-end primary-start] max-content [primary-end];
        grid-template-rows: max-content [progress-start] 4rem [progress-end form-start] 1fr [form-end] max-content;
        grid-gap: 1rem;
      }
      .title {
        grid-column: 1 / -1;
        margin-bottom: 1rem;
      }
      .progress {
        grid-column: 1 / -1;
        grid-row: progress-start / progress-end;
      }
      .form {
        grid-column: 1 / -1;
        grid-row: form-start / form-end;
        display: none;
      }
      .form[active] {
        display: block;
      }

      .cancel {
        grid-column: 1 / 2;
      }

      .prev {
        grid-column: secondary-start / secondary-end;
      }
      .submit {
        grid-column: primary-start / primary-end;
        --mdc-theme-secondary: var(--mdc-theme-primary);
        --mdc-theme-on-secondary: var(--mdc-theme-on-primary);
      }
      .spinner {
        grid-column: primary-start / primary-end;
      }
    `,
  ];

  cancel = () => this.setState({ confirmDialogOpen: true });

  previous = () => {
    this.setState({ step: this._state.step - 1 });
    scrolltop();
  };

  _onFormValidityChange = ({ detail: { valid } }) => {
    if (this._state.error) {
      this.setState({ formValid: false });
    }
    this.setState({ formValid: valid });
  };

  _submit = () => {
    if (this.currentForm.valid) {
      if (this._state.step < 3) {
        this.setState(this.currentForm.value);
        this._nextStep();
      } else {
        this.setState({ submitting: true });
        this._submitToServer()
          .then(({ id }) => {
            this.setState({
              step: 4,
              submitting: false,
              idPec: id,
              submitDialogOpen: true,
            });
          })
          .catch(err => {
            console.error(err);
            this.setState({
              submitting: false,
            });
            this.openSnackbar();
          });
      }
    }
  };

  _submitToServer = () => {
    const {
      actType,
      diseaseCode,
      patient,
      practitioner,
      prescriber,
      providerPresc,
      acts,
      attachments,
      observations,
      numPresc,
      numPrescFds,
      codcre,
    } = this._state;
    const pec = {
      actTypeId: actType.id,
      diseaseCode,
      practitionerId: practitioner.id,
      patientId: patient.id,
      prescriberId: prescriber.id,
      providerPrescId: providerPresc.id,
      ...this.hospitData,
      acts: acts.map(({ id, quantity, quantityPrint, unitPrice, toothNumber }) => ({
        id,
        quantity: quantityPrint > 0 ? Number(quantity) * Number(quantityPrint) : quantityPrint,
        quantityPrint: quantityPrint > 0 ? Number(quantityPrint) : quantity,
        unitPrice,
        toothNumber:
          toothNumber !== null && toothNumber.length > 0
            ? numArraytoString(toothNumber, ',')
            : null,
      })),
      attachments,
      observations,
      numPresc,
      numPrescFds,
      codcre,
    };
    return this.context.api.addPecRequest(pec);
  };

  _submitDialogClosed = () => {
    this.setState({ submitDialogOpen: false });
    if (this.router) this.router.navigate('/');
  };

  _confirmDialogClosed = ({ detail: { action } }) => {
    this.setState({ confirmDialogOpen: false });
    if (action === 'confirm') {
      if (this.router) this.router.navigate('/');
    }
  };

  get hospitData() {
    const { actType, admissionDate, estimatedDuration, actHospitTypeId } = this._state;
    if (Number(actType.id) === 8) {
      return {
        admissionDate,
        estimatedDuration,
        actHospitTypeId,
      };
    }
    return {};
  }

  _nextStep() {
    if (this._state.step < 3) {
      this.setState({ step: this._state.step + 1 });
      scrolltop();
    }
    if (this._state.step === 1 && this._state.from === 'PRESC') {
      this.setState({ numPresc: this._state.numPresc });
    }
  }

  render() {
    const { step } = this._state;
    return html`
      <main>
        <div class="container">
          <h1 class="headline4 title">Demande de prise en charge</h1>
          <athena-pec-progress
            .currentStepIndex="${step - 1}"
            .steps="${this._progressSteps}"
            class="progress"
          ></athena-pec-progress>

          ${this.active ? this._renderForm() : html``} ${this._renderActions()}
        </div>
        ${this._renderSubmitDialog()} ${this._renderConfirmDialog()}
        <mwc-snackbar id="snackbar" labelText="Une erreur est survenue. Réessayez ultérieurement">
          <mwc-icon-button icon="close" slot="dismiss"></mwc-icon-button>
        </mwc-snackbar>
      </main>
    `;
  }

  _renderForm() {
    const {
      patient,
      practitioner,
      actType,
      actsPreloaded,
      from,
      formAPI,
      step,
      diseaseCode,
      observations,
      admissionDate,
      estimatedDuration,
      acts,
      attachments,
      actHospitType,
    } = this._state;

    return html`
      <create-form-one
        ?active="${step === 1}"
        class="form"
        id="formOne"
        .api="${formAPI}"
        @validityChange="${this._onFormValidityChange}"
      >
      </create-form-one>

      <create-form-two
        .patient="${patient}"
        .actTypeId="${actType ? actType.id : ''}"
        .practitionerId="${practitioner ? practitioner.id : ''}"
        .actsPreloaded="${actsPreloaded}"
        .from="${from}"
        ?active="${step === 2}"
        class="form"
        id="formTwo"
        .api="${formAPI}"
        @validityChange="${this._onFormValidityChange}"
      >
      </create-form-two>

      <create-form-three
        hideHeader
        .actType="${actType}"
        .patient="${patient}"
        .practitioner="${practitioner}"
        diseaseCode="${diseaseCode}"
        observations="${observations}"
        .admissionDate="${admissionDate}"
        estimatedDuration="${estimatedDuration}"
        .actHospitType="${actHospitType}"
        .acts="${acts}"
        .attachments="${attachments}"
        ?active="${step >= 3}"
        class="form"
        id="formThree"
      >
      </create-form-three>
    `;
  }

  _renderActions() {
    const { step, formValid, submitting, submitDialogOpen } = this._state;
    if (submitDialogOpen) {
      return '';
    }
    const submitString = step === 3 ? 'soumettre' : 'suivant';
    return submitting
      ? html`
          <athena-pec-spinner class="spinner"></athena-pec-spinner>
        `
      : html`
          <mwc-button @click="${this.cancel}" class="cancel" label="Annuler"> </mwc-button>
          ${step !== 1
            ? html`
                <mwc-button
                  outlined
                  @click="${this.previous}"
                  class="prev"
                  label="Précédent"
                ></mwc-button>
              `
            : ''}
          <mwc-button
            @click="${this._submit}"
            ?disabled="${!formValid}"
            class="submit"
            raised
            label="${submitString}"
          >
          </mwc-button>
        `;
  }

  updated(changedProperties) {
    if (changedProperties.has('active') && this.active) {
      this.setState({
        idPec: '',
        submitting: false,
        submitDialogOpen: false,
        confirmDialogOpen: false,
        formValid: false,
        step: 1,
        actType: null,
        patient: null,
        practitioner: null,
        actHospitType: null,
        diseaseCode: '',
        observations: '',
        admissionDate: '',
        estimatedDuration: '',
        acts: [],
        attachments: null,
      });
    }
  }

  get currentForm() {
    switch (this._state.step) {
      case 1:
        return this.$('#formOne');
      case 2:
        return this.$('#formTwo');
      case 3:
        return this.$('#formThree');
      default:
        return null;
    }
  }

  async firstUpdated() {
    try {
      this.setState({ formAPI: this.context.api });
    } catch (err) {
      // console.error(err);
    }
  }

  setState(obj) {
    this._state = {
      ...this._state,
      ...obj,
    };
  }

  /**
   * @param {string} selector
   */
  $(selector) {
    return this.shadowRoot.querySelector(selector);
  }

  _renderSubmitDialog() {
    const { submitDialogOpen, idPec } = this._state;
    return html`
      <mwc-dialog ?open="${submitDialogOpen}" @closed="${this._submitDialogClosed}">
        <div>Demande soumise sous le numéro: <span class="">${idPec}</span></div>
        <mwc-button slot="primaryAction" dialogAction="discard">
          OK
        </mwc-button>
      </mwc-dialog>
    `;
  }

  _renderConfirmDialog() {
    const { confirmDialogOpen } = this._state;
    return html`
      <mwc-dialog ?open="${confirmDialogOpen}" @closed="${this._confirmDialogClosed}">
        <div>Voulez-vous vraiment quitter ?</div>
        <mwc-button slot="primaryAction" dialogAction="discard" dialogInitialFocus>
          Non
        </mwc-button>
        <mwc-button slot="secondaryAction" dialogAction="confirm">
          Oui
        </mwc-button>
      </mwc-dialog>
    `;
  }

  openSnackbar() {
    setTimeout(() => {
      this.$('#snackbar').open();
    }, 100);
  }
}
