import { LitElement, html, customElement, property, css } from 'lit-element';
import '@material/mwc-snackbar';
import '@material/mwc-button';
import '@material/mwc-icon-button';
import mdcTypography from '@wces/mdc-typography';
import baseStyle from './base-style.js';
import './athena-pec-spinner.js';

@customElement('athena-pec-view')
export class AthenaPecView extends LitElement {
  @property({ type: Boolean }) active = false;

  @property({ type: Boolean }) loading = false;

  @property({ type: Boolean }) exec = true;

  /**
   * @type {{ api: { getPecRequest: (id: String) => any}}}
   */
  @property({ type: Object }) context;

  /**
   * @type {{ params: { id: String }}}
   */
  @property({ type: Object }) routingParams;

  /**
   * @type { {navigate: (url: String) => Promise}}
   */
  @property({ type: Object }) router;

  /**
   * @type {import('../types').IPecRequest}
   */
  @property({ type: Object }) _pec;

  static styles = [
    mdcTypography,
    baseStyle,
    css`
      main {
        display: grid;
        grid-template-columns: minmax(3.75rem, 1fr) [center-start] minmax(min-content, 70rem) [center-end] minmax(
            3.75rem,
            1fr
          );
      }
      .container {
        grid-column: center-start / center-end;
        display: grid;
        grid-template-columns: max-content 1fr max-content max-content;
        grid-template-rows: max-content [form-start] 1fr [form-end] max-content;
        grid-gap: 1rem;
      }
      .title {
        grid-column: 1 / -1;
        margin-bottom: 1rem;
      }

      .form {
        grid-column: 1 / -1;
        grid-row: form-start / form-end;
      }

      .cancel {
        grid-column: 1 / 2;
        grid-row: 3 / 4;
      }
      .submit {
        grid-column: 4 / 5;
        grid-row: 3 / 4;
      }
      .print {
        grid-column: 3 / 4;
        grid-row: 3 / 4;
      }
      .spinner-container {
        display: flex;
        justify-content: center;
      }
    `,
  ];

  quit = () => {
    this.router.navigate('/');
  };

  execute = () => {
    this.router.navigate(`/execute/${this._pec.id}`);
  };

  print = () => {
    this.router.navigate(`/print/${this._pec.id}/${this.exec}`);
  };

  snackbarClosed = ({ detail: { reason } }) => {
    console.log(reason);
    if (reason === 'action') {
      this.fetchData();
    } else {
      // QUIT
      this.quit();
    }
  };

  render() {
    if (this.loading) {
      return html`
        <div class="spinner-container">
          <athena-pec-spinner class="spinner"></athena-pec-spinner>
        </div>
        ${this.renderSnackBar()}
      `;
    }

    if (!this._pec) {
      return html`
        ${this.renderSnackBar()}
      `;
    }

    const {
      id,
      patient,
      practitioner,
      actType,
      diseaseCode,
      observations,
      admissionDate,
      estimatedDuration,
      acts,
      attachments,
      status,
      actHospitType,
      rejectionReason,
    } = this._pec;

    /**
     * @type {import('../types').HospitCond}
     */
    const hospitCond = {
      dailyMaxAmount: this._pec.dailyMaxAmount,
      maxAmount: this._pec.maxAmount,
      patientAmoutRate: this._pec.patientAmoutRate,
      roomMaxAmount: this._pec.roomMaxAmount,
      supportedAmoutRate: this._pec.supportedAmoutRate,
      validFrom: this._pec.validFrom,
      validTo: this._pec.validTo,
    };

    const editable = status === 'VALIDATED';
    const printable =
      status === 'EXECUTED' || (Number(this._pec.actType.id) === 8 && status === 'VALIDATED');
    /**
     * cette variable permet d'eviter que l'impression génere une execution
     * * */
    this.exec = !(Number(this._pec.actType.id) === 8 && status === 'VALIDATED');

    const showHospitCondition = Number(this._pec.actType.id) === 8 && (editable || printable);

    return html`
      <main>
        <div class="container">
          <h1 class="headline4 title">Demande de prise en charge n° ${id}</h1>
          <create-form-three
            ?showHospitCondition="${showHospitCondition}"
            .hospitCond="${hospitCond}"
            title=""
            ?editable="${editable}"
            .actType="${actType}"
            .patient="${patient}"
            .practitioner="${practitioner}"
            diseaseCode="${diseaseCode}"
            observations="${observations || ''}"
            rejectionReason="${rejectionReason || ''}"
            .admissionDate="${admissionDate}"
            estimatedDuration="${estimatedDuration || ''}"
            .actHospitType="${actHospitType.name || ''}"
            .acts="${acts}"
            .attachments="${attachments}"
            active
            class="form"
          >
          </create-form-three>
          <mwc-button @click="${this.quit}" class="cancel" label="Quitter"></mwc-button>
          ${editable
            ? html`
                <mwc-button outlined @click="${this.execute}" class="submit" label="Exécuter">
                </mwc-button>
              `
            : ''}
          ${printable
            ? html`
                <mwc-button outlined @click="${this.print}" class="print" label="Imprimer">
                </mwc-button>
              `
            : ''}
        </div>
        ${this.renderSnackBar()}
      </main>
    `;
  }

  async updated(changedProperties) {
    if (changedProperties.has('routingParams')) {
      this.fetchData();
    }
  }

  async fetchData() {
    this._pec = null;
    this.loading = true;
    try {
      const { id } = this.routingParams.params;
      this._pec = await this.context.api.getPecRequest(id);
    } catch (err) {
      console.error(err);
      this.openSnackbar();
    } finally {
      this.loading = false;
    }
  }

  // eslint-disable-next-line class-methods-use-this
  renderSnackBar() {
    return html`
      <mwc-snackbar
        timeoutMs="10000"
        @MDCSnackbar:closed="${this.snackbarClosed}"
        id="snackbar"
        labelText="Une erreur est survenue. Retour dans 10 secondes."
      >
        <mwc-button slot="action">REESSAYER</mwc-button>
        <mwc-icon-button icon="close" slot="dismiss"></mwc-icon-button>
      </mwc-snackbar>
    `;
  }

  openSnackbar() {
    setTimeout(() => {
      this.$('#snackbar').open();
    }, 100);
  }

  $(selector) {
    return this.shadowRoot.querySelector(selector);
  }
}
