import { LitElement, html, property, customElement, css } from 'lit-element';
import mdcTypography from '@wces/mdc-typography';
import { classMap } from 'lit-html/directives/class-map';
import baseStyle from './base-style.js';

@customElement('athena-pec-status')
export class AthenaPecStatus extends LitElement {
  @property({ type: String }) status;

  static styles = [
    baseStyle,
    mdcTypography,
    css`
      .validated {
        color: green;
      }
      .rejected {
        color: red;
      }
      .executed {
        color: var(--mdc-theme-primary, blue);
      }
    `,
  ];

  render() {
    const { text, classObj } = this.options;
    return html`
      <span class="${classMap(classObj)}">${text}</span>
    `;
  }

  get options() {
    switch (this.status) {
      case 'VALIDATED':
        return {
          text: 'Validée',
          classObj: {
            validated: true,
          },
        };
      case 'REJECTED':
        return {
          text: 'Rejetée',
          classObj: {
            rejected: true,
          },
        };
      case 'EXECUTED':
        return {
          text: 'Exécutée',
          classObj: {
            executed: true,
          },
        };
      default:
        return {
          text: 'En attente',
          classObj: {
            wainting: true,
          },
        };
    }
  }
}
