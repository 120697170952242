/**
 *
 * @param {String} value1
 * @param {String} value2
 */
export const compare = (value1, value2) => {
  const v1 = value1.toUpperCase();
  const v2 = value2.toUpperCase();
  if (v1 > v2) return 1;
  if (v1 < v2) return -1;
  return 0;
};

export const sortBy = (arr, prop) => {
  const copyArr = [...arr];
  copyArr.sort((o1, o2) => compare(o1[prop], o2[prop]));
  return copyArr;
};
