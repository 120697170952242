import { LitElement, html, customElement, property, css } from 'lit-element';
import '@wces/datatable/wces-datatable.js';
import baseStyle from './base-style.js';
import { formatNumber } from '../utils/format.js';

@customElement('athena-pec-act-display')
export class AthenaPecActDisplay extends LitElement {
  /** @typedef {import('../types').Act} Act */
  /** @type {Act[]} */
  @property({ type: Array }) acts;

  /** @type { number } act familly id */
  @property({ type: Number }) actTypeId;

  @property({ type: Boolean }) editable = false;

  @property({ type: Boolean }) hideHeader = false;

  header = [{ name: 'description', displayName: 'Acte médical' }];

  priceHeader = {
    name: '',
    displayName: 'Montant',
    isNumeric: true,
    /** @type { (act: Act, tag: any) => import('lit-html').TemplateResult} */
    renderer: ({ quantity, quantityPrint, unitPrice, totalPrice }, tag) => tag`
      <span>${formatNumber(
        totalPrice || Number(quantity) * Number(unitPrice) * Number(quantityPrint),
      )}</span>
    `,
  };

  acceptedPriceHeader = {
    name: '',
    displayName: 'Montant pris en charge',
    renderer: ({ acceptedTotalPrice }, tag) => tag`
      <div style="text-align: right;">${formatNumber(acceptedTotalPrice)}</div>
    `,
  };

  toothNumberHeader = {
    name: 'toothNumber',
    displayName: 'N° dent',
  };

  static styles = [
    baseStyle,
    css`
      wces-datatable {
        font-size: 0.875rem;
      }
    `,
  ];

  render() {
    const header = [...this.header];
    if (this.actTypeId === 6) {
      header.push(this.toothNumberHeader);
    }
    header.push(this.priceHeader);
    if (this.acts && this.acts.length && this.acts[0].acceptedTotalPrice) {
      header.push(this.acceptedPriceHeader);
    }

    return html`
      <wces-datatable
        ?hideHeader="${this.hideHeader}"
        .header="${header}"
        .data="${this.acts}"
      ></wces-datatable>
    `;
  }
}
