import { LitElement, html, customElement, property, css } from 'lit-element';
import mdcTypography from '@wces/mdc-typography';
import '@wces/datatable/wces-datatable.js';
import baseStyle from './base-style.js';
import './athena-pec-act-display.js';
import './athena-pec-file-display.js';
import { formatDate, formatNumber } from '../utils/format.js';

@customElement('create-form-three')
export class CreateFormThree extends LitElement {
  @property({ type: String }) title = 'Résumé';

  @property({ type: Boolean }) editable = false;

  @property({ type: Boolean }) showHospitCondition = false;

  @property({ type: Boolean }) hideHeader = false;

  /** @type {import('../types').Patient} */
  @property({ type: Object }) patient;

  /** @type {string} Pactilis referential disease code */
  @property({ type: String }) diseaseCode;

  @property({ type: String }) observations;

  @property({ type: String }) rejectionReason;

  /** @type {import('../types').Practitionner} */
  @property({ type: Object }) practitioner;

  /** @type {Date} Hospit expected admission date */
  @property({ type: Object }) admissionDate;

  /** @type {import('../types').ActHospitType} */
  @property({ type: Object }) actHospitType;

  /** @type {number} Hospit estimated duration */
  @property({ type: Number }) estimatedDuration;

  /** @type {import('../types').ActType} */
  @property({ type: Object }) actType;

  /** @type {import('../types').Act[]} */
  @property({ type: Array }) acts;

  /**
   * @type {import('../types').HospitCond}
   */
  @property({ type: Object }) hospitCond;

  @property({ type: Array }) attachments = [];

  static styles = [
    baseStyle,
    mdcTypography,
    css`
      .container {
        display: grid;
        grid-template-columns: [center-start] 1fr [center-end];
        grid-template-rows: min-content 1fr;
        grid-row-gap: 1rem;
        border: solid 1px rgba(0, 0, 0, 0.01);
        padding: 3rem 1.5rem;
        box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.08);
      }
      .title {
        grid-column: 1 / -1;
      }
      .content {
        grid-column: center-start / center-end;
        display: grid;
        grid-template-columns: max-content 1fr;
        grid-row-gap: 1rem;
        grid-column-gap: 2rem;
        justify-self: center;
      }
      .hospit-title {
        grid-column: 1 / -1;
        margin-top: 0.5rem;
        padding: 0.5rem;
        background: rgba(0, 0, 0, 0.08);
        font-weight: bold;
      }
      .hospit-info-more {
        grid-column: 1 / -1;
        font-weight: bold;
        max-width: 48rem;
      }
      .key {
        font-weight: bold;
      }
    `,
  ];

  attachmentsHeader = [
    {
      name: '',
      displayName: 'Pièces jointes',
      renderer: ({ name, filename }, tag) => tag`${filename || name}`,
    },
  ];

  render() {
    if (this.patient && this.practitioner && this.actType) {
      return html`
        <div class="container">
          <div class="content">
            <h4 class="title headline6">${this.title}</h4>
            <span class="key subtitle2">Matricule</span>
            <span class="value">${this.patient.id}</span>

            <span class="key subtitle2">Patient</span>
            <span class="value">${this.patient.name}</span>

            <span class="key subtitle2">Type</span>
            <span class="value">${this.actType.name}</span>

            <span class="key subtitle2">Affection</span>
            <span class="value">${this.diseaseCode}</span>

            <span class="key subtitle2">Prestataire médical</span>
            <span class="value">${this.practitioner.name}</span>

            ${this._renderHospit()}

            <span class="key subtitle2">Actes médicaux</span><span></span><span></span>
            <athena-pec-act-display
              ?hideHeader="${this.hideHeader}"
              ?editable="${this.editable}"
              class="value"
              .acts="${this.acts}"
              actTypeId="${this.actType.id}"
            >
            </athena-pec-act-display>

            <span class="key subtitle2">Pièces jointes</span>
            <athena-pec-file-display class="value" .files="${this.attachments}">
            </athena-pec-file-display>

            <span class="key subtitle2">Remarques</span>
            <span class="value">${this.observations}</span>
            ${this.rejectionReason
              ? html`
                  <span class="key subtitle2">Motifs du rejet</span>
                  <span class="value">${this.rejectionReason}</span>
                `
              : ``}
            ${this.showHospitCondition
              ? html`
                  <h6 class="overline hospit-title">Conditions de prise en charge</h6>

                  <span class="key subtitle2">Période de validité</span>
                  <span class="value"
                    >${formatDate(this.hospitCond.validFrom)} au
                    ${formatDate(this.hospitCond.validTo)}</span
                  >

                  <span class="key subtitle2">Taux de prise en charge</span>
                  <span class="value">${this.hospitCond.supportedAmoutRate}%</span>

                  <span class="key subtitle2">Taux à la charge du patient</span>
                  <span class="value">${this.hospitCond.patientAmoutRate}%</span>
                  ${this.hospitCond.roomMaxAmount !== 0
                    ? html`
                        <span class="key subtitle2">Plafond chambre</span>
                        <span class="value">${formatNumber(this.hospitCond.roomMaxAmount)}</span>
                      `
                    : ''}
                  ${this.hospitCond.maxAmount !== 0
                    ? html`
                        <span class="key subtitle2">Plafond global</span>
                        <span class="value">${formatNumber(this.hospitCond.maxAmount)}</span>
                      `
                    : ''}

                  <p class="hospit-info-more">
                    Les dépassements de plafonds et frais personnel (téléphone, location téléviseur,
                    etc.) sont exclusivement à la charge du patient. Facturation suivant
                    Règlementation et / ou Convention en vigueur.
                  </p>
                `
              : ''}
          </div>
        </div>
      `;
    }
    return '';
  }

  _renderHospit() {
    if (this.actType && Number(this.actType.id) === 8) {
      return html`
        <span class="key subtitle2">Type d'hospitalisation</span>
        <span class="value">${this.actHospitType.name}</span>
        <span class="key subtitle2">Date d'entrée</span>
        <span class="value">${formatDate(this.admissionDate)}</span>
        <span class="key subtitle2">Durée prévisionnelle</span>
        <span class="value">${this.estimatedDuration}</span>
      `;
    }
    return html``;
  }

  // eslint-disable-next-line class-methods-use-this
  get valid() {
    return true;
  }
}
