import { gql } from '@wces/apollo-client';

const GET_PEC_REQUESTS = gql`
  query GetPecRequests {
    pecRequests {
      id
      date
      patient {
        id
        name
      }
      actType {
        id
        name
      }
      status
    }
  }
`;

const ADD_PEC_REQUEST = gql`
  mutation addPECRequest($pecRequestInput: PECRequestInput!) {
    addPECRequest(pecRequest: $pecRequestInput) {
      id
      date
      patient {
        id
        name
      }
      actType {
        id
        name
      }
      status
    }
  }
`;

const GET_PEC_REQUEST = gql`
  query GetPecRequest($requestId: ID!) {
    pecRequest(requestId: $requestId) {
      id
      numfds
      date
      status
      execNumFds
      numPresc
      numPrescFds
      codcre
      supportedAmoutRate
      maxAmount
      dailyMaxAmount
      roomMaxAmount
      patientAmoutRate
      validFrom
      validTo
      patient {
        id
        name
      }
      provider {
        id
        name
      }
      diseaseCode
      observations
      rejectionReason
      practitioner {
        id
        name
      }
      prescriber {
        id
        name
      }
      providerPresc {
        id
        name
      }
      admissionDate
      estimatedDuration
      actType {
        id
        name
      }
      actHospitType {
        id
        name
      }
      acts {
        id
        description
        quantity
        quantityPrint
        unitPrice
        toothNumber
        totalPrice
        status
        acceptedTotalPrice
        items {
          id
          totalPrice
          acceptedTotalPrice
        }
      }
      attachments {
        filename
        mimetype
        encoding
        key
      }
    }
  }
`;

const EXECUTE_PEC_REQUEST = gql`
  mutation executePecRequest($pecRequestExecution: PecRequestExecutionInput!) {
    executePECRequest(pecRequestExecution: $pecRequestExecution) {
      id
      execNumFds
      date
      patient {
        id
        name
      }
      actType {
        id
        name
      }
      status
    }
  }
`;

const PEC_REQUEST_ADDED = gql`
  subscription pecRequestAdded {
    pecRequestAdded {
      id
      date
      patient {
        id
        name
      }
      actType {
        id
        name
      }
      status
    }
  }
`;
const PEC_REQUEST_UPDATED = gql`
  subscription pecRequestUpdated {
    pecRequestUpdated {
      id
      date
      patient {
        id
        name
      }
      actType {
        id
        name
      }
      status
    }
  }
`;

const PEC_REQUEST_EXECUTED = gql`
  subscription pecRequestExecuted {
    pecRequestExecuted {
      id
      date
      patient {
        id
        name
      }
      actType {
        id
        name
      }
      status
    }
  }
`;

export {
  GET_PEC_REQUESTS,
  ADD_PEC_REQUEST,
  GET_PEC_REQUEST,
  PEC_REQUEST_ADDED,
  PEC_REQUEST_UPDATED,
  PEC_REQUEST_EXECUTED,
  EXECUTE_PEC_REQUEST,
};
