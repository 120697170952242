// @ts-check
import {
  ApolloClient,
  ApolloLink,
  InMemoryCache,
  createUploadLink,
  concat,
  defaultDataIdFromObject,
  WebSocketLink,
  split,
  getMainDefinition,
} from '@wces/apollo-client';

export const createApolloClient = ({ serverUrl, serverSubUrl, tokenProvider }) => {
  const wsLink = new WebSocketLink({
    uri: serverSubUrl,
    options: {
      reconnect: true,
      connectionParams: {
        authToken: tokenProvider.getToken(),
      },
    },
  });

  const httpLink = createUploadLink({
    uri: serverUrl,
  });

  const authMiddleware = new ApolloLink((operation, forward) => {
    // add the authorization to the headers
    const token = tokenProvider.getToken();

    operation.setContext({
      headers: {
        authorization: token ? `Bearer ${token}` : null,
      },
    });

    return forward(operation);
  });

  const splitLink = split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
    },
    wsLink,
    concat(authMiddleware, httpLink),
  );

  return new ApolloClient({
    cache: new InMemoryCache({
      dataIdFromObject: object => {
        switch (object.__typename) {
          case 'Act':
            return null; // use the `key` field as the identifier
          case 'ActItem':
            return null; // use the `key` field as the identifier
          default:
            return defaultDataIdFromObject(object); // fall back to default handling
        }
      },
    }),
    link: splitLink,
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'cache-and-network',
        errorPolicy: 'ignore',
      },
      query: {
        fetchPolicy: 'network-only',
        errorPolicy: 'all',
      },
      mutate: {
        errorPolicy: 'all',
      },
    },
  });
};
