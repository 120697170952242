import { LitElement, html, property, customElement, css } from 'lit-element';
import { live } from 'lit-html/directives/live';
import mdcTypography from '@wces/mdc-typography';
import '@material/mwc-icon';
import '@wces/datatable/wces-datatable.js';
import baseStyle from './base-style.js';

@customElement('athena-pec-file-input')
export class AthenaPecFileInput extends LitElement {
  @property({ type: Array }) files = [];

  _removeFile = ({ target: { file } }) => {
    this.files = this.files.filter(f => f !== file);
    this.dispatchEvent(new CustomEvent('change', { detail: { files: this.files } }));
  };

  header = [
    {
      name: 'name',
      displayName: 'Pièces jointes',
    },
    {
      name: '',
      renderer: (row, tag) => tag`
      <mwc-icon-button
        @click="${this._removeFile}" .file="${row}" icon="delete_outline">
      </mwc-icon-button>`,
    },
  ];

  static styles = [
    baseStyle,
    mdcTypography,
    css`
      #file {
        width: 0.1px;
        height: 0.1px;
        opacity: 0;
        overflow: hidden;
        position: absolute;
        z-index: -1;
      }
      .label {
        display: inline-flex;
        font-family: var(
          --mdc-typography-button-font-family,
          var(--mdc-typography-font-family, Roboto, sans-serif)
        );
        font-size: var(--mdc-typography-button-font-size, 0.875rem);
        font-weight: var(--mdc-typography-button-font-weight, 500);
        letter-spacing: var(--mdc-typography-button-letter-spacing, 0.0892857em);
        text-transform: var(--mdc-typography-button-text-transform, uppercase);
        align-items: center;
        justify-content: center;
        color: var(--mdc-theme-primary);
        padding: 0 1rem;
        height: 2.25rem;
        border: solid 1px rgba(0, 0, 0, 0.08);
        transition: all 0.3s;
        border-radius: 0.25rem;
        margin-bottom: 0.5rem;
      }
      .label:hover {
        color: var(--mdc-theme-on-primary, #fff);
        background-color: var(--mdc-theme-primary);
        cursor: pointer;
      }
      .label:active {
        opacity: 0.6;
      }
      .icon {
        --mdc-icon-size: 1.125rem;
        margin: 0 0.5rem 0 -0.25rem;
      }
      wces-datatable {
        --mdc-icon-size: 1rem;
        --mdc-icon-button-size: 1.625rem;
        font-size: 0.875rem;
      }
    `,
  ];

  _change = e => {
    e.preventDefault();
    const { validity, files: newFiles } = e.target;
    if (validity.valid) {
      this.files = [...newFiles, ...this.files];
      this.dispatchEvent(new CustomEvent('change', { detail: { files: this.files } }));
    }
  };

  render() {
    return html`
      <div class="container">
        <input id="file" multiple @change="${this._change}" type="file" .value="${live('')}" />
        <label class="label" for="file">
          <mwc-icon class="icon">attach_file </mwc-icon>
          Ajouter
        </label>
        <wces-datatable
          hideHeader
          condensed
          .header="${this.header}"
          .data="${this.files}"
        ></wces-datatable>
      </div>
    `;
  }
}
