import { LitElement, html, property, customElement, css } from 'lit-element';
import mdcTypography from '@wces/mdc-typography';
import baseStyle from './base-style.js';

@customElement('athena-pec-file-display')
export class AthenaPecFileDisplay extends LitElement {
  @property({ type: Array }) files = [];

  static styles = [
    baseStyle,
    mdcTypography,
    css`
      .container {
        display: grid;
        grid-template-columns: 1fr;
        grid-gap: 0.25rem;
      }
    `,
  ];

  render() {
    if (this.files && this.files.length) {
      return html`
        <div class="container">
          ${this.files.map(
            ({ name, filename }) => html`
              <div class="file">${filename || name}</div>
            `,
          )}
        </div>
      `;
    }
    return '';
  }
}
