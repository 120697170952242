import { css } from 'lit-element';

export default css`
*,
*::before,
*::after {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
`;
