import { LitElement, customElement, html, css, property } from 'lit-element';
import '@material/mwc-fab';
import '@material/mwc-snackbar';
import '@wces/datatable/wces-datatable.js';
import mdcTypography from '@wces/mdc-typography';
import baseStyle from './base-style.js';
import { formatDate } from '../utils/format.js';
import './athena-pec-status.js';
import './athena-pec-spinner.js';

@customElement('athena-pec-list')
export class AthenaPecList extends LitElement {
  @property({ type: Boolean }) active = false;

  @property({ type: Object }) context;

  /**
   * @type {{navigate: Function<String, Promise<void>>}}
   */
  @property({ type: Object }) router;

  @property({ type: Array }) _data = [];

  @property({ type: Boolean }) _loading = true;

  view = ({ target: { id } }) => {
    try {
      this.router.navigate(`/view/${id}`);
    } catch (err) {
      console.error(err); // will deal with that later
    }
  };

  execute = ({ target: { id } }) => {
    try {
      this.router.navigate(`/execute/${id}`);
    } catch (err) {
      console.error(err); // will deal with that later
    }
  };

  header = [
    { name: 'id', displayName: 'Numéro' },
    { name: 'date', displayName: 'Date', renderer: row => formatDate(new Date(row.date)) },
    { name: 'patient.id', displayName: 'Matricule' },
    { name: 'patient.name', displayName: 'Patient' },
    { name: 'actType.name', displayName: 'Type' },
    {
      name: 'status',
      displayName: 'Statut',
      renderer: (row, tag) => tag`<athena-pec-status .status="${row.status}"></athena-pec-status>`,
    },
    {
      name: '',
      renderer: (row, tag) => tag`
        <div>
          <mwc-icon-button
            id="${row.id}" title="Voir" @click="${this.view}" icon="visibility">
          </mwc-icon-button>
        </div>
      `,
    },
  ];

  static styles = [
    baseStyle,
    mdcTypography,
    css`
      main {
        display: grid;
        grid-template-columns: minmax(3.75rem, 1fr) [center-start] minmax(min-content, 70rem) [center-end] minmax(
            3.75rem,
            1fr
          );
      }

      .container {
        grid-column: center-start / center-end;
        display: grid;
        grid-template-columns: 1fr min-content;
        grid-template-rows: repeat(3, min-content);
        grid-row-gap: 1rem;
      }
      .title {
        grid-column: 1 / -1;
        margin-bottom: 1rem;
      }
      .table {
        grid-row: 3 / 4;
        grid-column: 1 / -1;
      }
      .add-btn {
        grid-column: 2 / 3;
        --mdc-theme-secondary: var(--mdc-theme-primary);
        --mdc-theme-on-secondary: var(--mdc-theme-on-primary);
      }
      .spinner-container {
        display: flex;
        justify-content: center;
      }
    `,
  ];

  create = () => {
    try {
      this.router.navigate('/create');
    } catch (err) {
      console.error(err); // will deal with that later
    }
  };

  render() {
    if (this._loading) {
      return html`
        <div class="spinner-container">
          <athena-pec-spinner class="spinner"></athena-pec-spinner>
        </div>
      `;
    }
    return html`
      <main>
        <div class="container">
          <h1 class="title headline4">Demandes de prise en charge</h1>
          <mwc-fab
            @click="${this.create}"
            class="add-btn"
            extended
            icon="add"
            label="Ajouter"
          ></mwc-fab>
          <wces-datatable .header="${this.header}" .data="${this._data}" class="table">
          </wces-datatable>
        </div>
      </main>
    `;
  }

  firstUpdated() {
    this.context.api.watchPecRequests(({ pecRequests, error }) => {
      this._loading = false;
      if (error) {
        console.error(error);
      } else {
        this._data = pecRequests.slice().sort((a, b) => b.date - a.date);
      }
    });
  }

  shouldUpdate() {
    return this.active;
  }
}
