import { LitElement, html, customElement, css, property } from 'lit-element';
import '@material/mwc-textfield';
import '@material/mwc-list';
import '@material/mwc-icon-button';
import '@material/mwc-button';
import '@material/mwc-list/mwc-check-list-item';
import baseStyle from './base-style.js';

@customElement('athena-pec-add-teeth')
export class AthenaPecAddTeeth extends LitElement {
  @property({ type: Array }) data = [];

  @property({ type: Boolean }) iconButton = false;

  @property({ type: Array }) _teethNum = [];

  @property({ type: Boolean }) open = false;

  @property({ type: Object }) value;

  @property({ type: String }) _searchText = '';

  _value = null;

  get value() {
    return {
      _value: this._value,
    };
  }

  static styles = [
    baseStyle,
    css`
      .container {
        display: grid;
        display: block;
        position: relative;
      }
      .content {
        display: grid;
        grid-template-columns: 50px 50px 50px 50px 50px 50px 50px;
        grid-template-rows: 50px 50px 50px 50px 50px;
        visibility: hidden;
        opacity: 0;
        transition: all 0.1s;
        position: absolute;
        left: 0;
        right: 0;
        z-index: 100;
        background-color: var(--mdc-theme-background, #ffffff);
        box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.08);
        border: solid 1px rgba(0, 0, 0, 0.08);
        border-radius: 0 0 4px 4px;
      }
      .content[open] {
        display: grid;
        grid-template-columns: 40px 40px 40px 40px 40px;
        grid-template-rows: 40px 40px 40px 40px 40px 40px 40px 40px;
        visibility: visible;
        opacity: 1;
        max-height: none;
        overflow-y: auto;
      }
      .tooth[selected] {
        --mdc-theme-primary: #e9437a;
        --mdc-theme-on-primary: white;
      }
    `,
  ];

  // _addTooth(toothElement) {
  //   console.log("===========>>>>>>>",JSON.stringify(this.data), "===========>>>>>>>", JSON.stringify(toothElement));
  //   const tooth = this.data.find(t => toothElement.path.includes(this.$(`#${t.hmtlId}`)));

  //   if (tooth !== undefined && Number(tooth._id) >= 0) {
  //     this._value = tooth._id;
  //     if (tooth.selected) {
  //       this.dispatchEvent(new CustomEvent('remove', { detail: { value: this.value } }));
  //     } else {
  //       this.dispatchEvent(new CustomEvent('select', { detail: { value: this.value } }));
  //     }
  //   }
  //   this._close();
  // }

  _addTooth = e => {
    const path = e.path || (e.composedPath && e.composedPath());
    if (!path.includes(this.$('#content'))) {
      this._close();
    } else {
      const tooth = this.data.find(t => path.includes(this.$(`#${t.hmtlId}`)));
      if (tooth !== undefined && Number(tooth._id) >= 0) {
        this._value = tooth._id;
        if (tooth.selected) {
          this.dispatchEvent(new CustomEvent('remove', { detail: { value: this.value } }));
        } else {
          this.dispatchEvent(new CustomEvent('select', { detail: { value: this.value } }));
        }
      }
      this._close();
    }
  };

  _close = () => {
    setTimeout(() => {
      this.open = false;
      if (this.value) {
        this._value = null;
      }
    }, 10);
  };

  showSelect() {
    setTimeout(() => {
      this.open = true;
    }, 10);
  }

  render() {
    return html`
      <div class="container" id="content">
        <mwc-icon-button icon="add" @click="${this.showSelect}" id="addselect"> </mwc-icon-button>
        <div class="content" ?open="${this.open}">
          ${this.data.map(
            item => html`
              <mwc-button
                id="${item.hmtlId}"
                label="${item.name}"
                @click="${this._addTooth}"
                ?selected="${item.selected}"
                class="tooth"
              ></mwc-button>
            `,
          )}
        </div>
      </div>
    `;
  }

  _handleClick = e => {
    const path = e.path || (e.composedPath && e.composedPath());
    if (!path.includes(this.$('#content'))) {
      this._close();
    } else {
      const tooth = this.data.find(t => path.includes(this.$(`#${t.hmtlId}`)));
      if (tooth !== undefined) {
        this.clikedId = tooth._id;
      }
    }
  };

  connectedCallback() {
    if (super.connectedCallback) {
      super.connectedCallback();
    }
    document.addEventListener('click', this._handleClick);
  }

  disconnectedCallback() {
    document.removeEventListener('click', this._handleClick);
    if (super.disconnectedCallback) {
      super.disconnectedCallback();
    }
  }

  get isValid() {
    return this.data.includes(this.value);
  }

  clear() {
    this._value = null;
  }

  $(selector) {
    return this.shadowRoot.querySelector(selector);
  }
}
