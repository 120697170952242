import { LitElement, html, customElement, css, property } from 'lit-element';
import { live } from 'lit-html/directives/live';
import '@material/mwc-textfield';
import '@material/mwc-list';
import '@material/mwc-list/mwc-check-list-item';
import baseStyle from './base-style.js';
import { debounce } from '../utils/debounce.js';

@customElement('athena-pec-autocomplete')
export class AthenaPecAutocomplete extends LitElement {
  @property({ type: Array }) data = [];

  @property({ type: Boolean }) open = false;

  @property({ type: Object }) value;

  @property({ type: String }) _searchText = '';

  _value = null;

  get value() {
    return this._value;
  }

  static styles = [
    baseStyle,
    css`
      .container {
        display: block;
        position: relative;
      }
      .input {
        width: 100%;
      }
      .content {
        visibility: hidden;
        opacity: 0;
        transition: all 0.1s;
        position: absolute;
        left: 0;
        right: 0;
        z-index: 100;
        background-color: var(--mdc-theme-background, #ffffff);
        box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.08);
        border: solid 1px rgba(0, 0, 0, 0.08);
        border-radius: 0 0 4px 4px;
      }
      .content[open] {
        visibility: visible;
        opacity: 1;
        max-height: 15rem;
        overflow-y: auto;
      }
      mwc-list-item {
        padding: 0 8px;
      }
    `,
  ];

  _search = debounce(() => {
    this.open = true;
    const { value } = this.$('#search');
    this._searchText = value;
    this.dispatchEvent(new CustomEvent('search', { detail: { value } }));
  });

  _select = () => {
    const { index } = this.$('#list');
    if (index >= 0) {
      this._value = this.data[index];
      this._searchText = this._value.name;
      this.dispatchEvent(new CustomEvent('select', { detail: { value: this.value } }));
    }
    this._close();
  };

  _keydownOnSearch = e => {
    const code = e.keyCode || e.which;
    switch (code) {
      case 13: // Enter
        break;
      case 27: // ESC
        e.preventDefault();
        this._close();
        break;
      default:
    }
  };

  _keydown = e => {
    const code = e.keyCode || e.which;
    switch (code) {
      case 13: // Enter
        this._select();
        break;
      case 27: // ESC
        e.preventDefault();
        this._select();
        break;
      default:
    }
  };

  _close = () => {
    setTimeout(() => {
      this.open = false;
      if (this.value) {
        this._searchText = this.value.name;
      }
    }, 10);
  };

  render() {
    return html`
      <div class="container" id="content">
        <mwc-textfield
          @keydown="${this._keydownOnSearch}"
          id="search"
          .value="${live(this._searchText)}"
          @input="${this._search}"
          @click="${this._search}"
          class="input"
          placeholder="Prestation"
          outlined
        >
        </mwc-textfield>
        <mwc-list
          id="list"
          @keydown="${this._keydown}"
          @click="${this._select}"
          ?open="${this.open}"
          class="content"
        >
          ${this.data.map(
            item => html`
              <mwc-list-item>${item.name}</mwc-list-item>
            `,
          )}
        </mwc-list>
      </div>
    `;
  }

  updated(changedProperties) {
    if (changedProperties.has('_searchText')) {
      setTimeout(() => {
        this.$('#search').focus();
      }, 10);
    }
  }

  _handleClick = e => {
    const path = e.path || (e.composedPath && e.composedPath());
    if (!path.includes(this.$('#content'))) {
      this._close();
    }
  };

  connectedCallback() {
    if (super.connectedCallback) {
      super.connectedCallback();
    }
    document.addEventListener('click', this._handleClick);
  }

  disconnectedCallback() {
    document.removeEventListener('click', this._handleClick);
    if (super.disconnectedCallback) {
      super.disconnectedCallback();
    }
  }

  get isValid() {
    return this.data.includes(this.value);
  }

  clear() {
    this._value = null;
    this._searchText = '';
  }

  focus() {
    this.$('#search').focus();
  }

  $(selector) {
    return this.shadowRoot.querySelector(selector);
  }
}
