import { LitElement, html, customElement, css, property } from 'lit-element';
import mdcTypography from '@wces/mdc-typography';
import { classMap } from 'lit-html/directives/class-map';
import baseStyle from './base-style.js';

@customElement('athena-pec-progress')
export class AthenaPecProgress extends LitElement {
  @property({ type: Array }) steps;

  @property({ type: Number }) currentStepIndex = 1;

  static styles = [
    baseStyle,
    mdcTypography,
    css`
      .progress {
        display: grid;
        grid-auto-columns: 1fr;
        grid-auto-flow: column;
        grid-template-rows: max-content;
      }

      .progress__item {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
      }
      .progress__step {
        width: 32px;
        height: 32px;
        border: 2px solid grey;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        background: white;
        z-index: 1;
      }
      .progress__bar {
        position: absolute;
        width: 100%;
        height: 2px;
        background-color: grey;
        transform: translateX(50%);
      }

      .progress__bar--ok {
        width: 0;
        height: 3px;
        background-color: var(--mdc-theme-primary, green);
        animation: nextStep 0.5s;
        animation-fill-mode: forwards;
      }

      .progress__text {
        position: absolute;
        top: calc(100% + 8px);
        text-align: center;
      }

      .progress__item:last-child .progress__bar {
        display: none;
      }

      .progress__item.ok .progress__step {
        border-color: var(--mdc-theme-primary, green);
        color: white;
        background: var(--mdc-theme-primary, green);
      }

      .progress__item.active .progress__step {
        color: var(--mdc-theme-primary, blue);
        border: 4px solid grey;
        animation: pulse 2s infinite 0.5s;
      }

      @keyframes nextStep {
        0% {
          width: 0%;
        }
        100% {
          width: 100%;
        }
      }

      @keyframes pulse {
        0% {
          box-shadow: 0 0 0 0 rgba(33, 131, 221, 0.4);
        }
        70% {
          box-shadow: 0 0 0 10px rgba(33, 131, 221, 0);
        }
        100% {
          box-shadow: 0 0 0 0 rgba(33, 131, 221, 0);
        }
      }
    `,
  ];

  render() {
    return html`
      <div class="progress">
        ${this.steps.map((step, index) => {
          const classes = {
            progress__item: true,
            active: index === this.currentStepIndex,
            ok: index < this.currentStepIndex,
          };
          const progressClasses = {
            progress__bar: true,
            'progress__bar--ok': index < this.currentStepIndex,
          };
          return html`
            <div class="${classMap(classes)}">
              <div class="progress__step">${index + 1}</div>
              <div class="progress__bar"></div>
              <div class="${classMap(progressClasses)}"></div>
              <div class="progress__text caption">${step}</div>
            </div>
          `;
        })}
      </div>
    `;
  }
}
